import React, { useContext, useEffect, useRef, useState } from "react";
// import Navbar from "./Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import { Button, Container, Grid } from "@mui/material";
import DataContext from "../../../context/DataContext";
import Footer from "../footer-page/Footer";
import HomeEventSection from "./HomeEventSection";
import HomeServicesSection from "./HomeServicesSection";
import BannerFilter from "./BannerFilter";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import Map from "./Map";
import MapSection from "./MapSection";
import Navbar from "../navbar-page/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Aos from "aos";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import cryptoJS from "../../../hooks/cryptoJS";
import Thiruvizha24 from "./thiruvizha24-page/Thiruvizha24";
import FoodModal from "./thiruvizha24-page/FoodModal";
const HomePage = () => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext)
  const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors } } = useForm();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isTamil, setIsTamil] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  // useEffect(() => {
  //   const changelang = setInterval(() => {
  //     setIsTamil((a) => !a);
  //   }, 6000);

  //   return () => clearInterval(changelang);
  // }, []);

  useEffect(() => {
    // Calculate the total time for one cycle of the Typewriter effect
    const typeSpeed = 200;
    const deleteSpeed = 50;
    const delaySpeed = 2000;

    // Calculate the duration of one complete typewriter cycle (typing + pause + deleting + pause)
    const totalCycleTime = (isTamil ? "தமிழ்நாடு" : "Tamil Nadu").length * typeSpeed
      + delaySpeed
      + (isTamil ? "தமிழ்நாடு" : "Tamil Nadu").length * deleteSpeed
      + delaySpeed;

    const changelang = setInterval(() => {
      setIsTamil((prevIsTamil) => !prevIsTamil);
    }, totalCycleTime);

    // Clear interval on component unmount
    return () => clearInterval(changelang);
  }, [isTamil]);

  const handlePlay = () => {
    setIsPlaying(true);
    const video = document.getElementById('video');
    if (!isPlaying) {
      video.play();
    } else {
      video.pause();
    }
  };

  const handleDownBtn = () => {
    const refdp = document.getElementById("about");
    refdp.scrollIntoView();
  }
  useDidMountEffect(() => {
    dashboardApi()
  }, [])
  const [dashboardData, setDashboardData] = useState({})
  const dashboardApi = () => {
    apiService('home/matrix', '', 'unauthget').then((result) => {
      if (result && result.data && result.data.response.responseStatus === 'Success') {
        setDashboardData(result.data)
      }
    }).catch((err) => {

    });
  }

  const [emailId, setEmailId] = useState('')
  const [checkEmail, setCheckEmail] = useState(false)
  const [emailErrorMsg, setEmailErrorMsg] = useState('')
  const handleEmailId = (e) => {
    setEmailId(e.target.value.toLowerCase())
    setEmailErrorMsg(validateEmail(e.target.value))
    setCheckEmail(false)
  }
  const handleNewsLetter = () => {
    console.log(emailErrorMsg)
    if (emailId !== '') {
      if (emailErrorMsg === 'Invalid email address') {
        return
      }
      var email = emailId;
      setCheckEmail(false);
      var req = {
        email: cryptoJS(emailId.toLowerCase())
      }
      console.log(email, "email")
      apiService(`home/newsletter/subscribe?email=${email}`, req, "post")
        .then((result) => {

          if (result && result.data && result.data.responseMessage) {
            notifyService('success', 'Success', result.data.responseMessage)
            setEmailId('')
          }
        }).catch((err) => {

        });
    } else {
      setCheckEmail(true)
    }

  }


  // BannerFilter Page

  // const { navigator } = useContext(DataContext)
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [soSearch, setSoSearch] = useState(false);
  const [finalVal, setFinalVal] = useState(false);
  const searchRef = useRef(null);
  useEffect(() => {
    Aos.init();
  }, []);
  useDidMountEffect(() => {
    profileGet();
  }, []);
  const toggleSearchVisibility = () => {

    setSearchVisible(!isSearchVisible);
    if (selectedValue !== "") {
      setSearchVisible(!isSearchVisible);
      setFinalVal(false);
      setSoSearch(false);
      setSelectedValue("");
    }
  };
  const [selectedProfileType, setSelectedProfileType] = useState("");
  const toggleSearch = (value) => {
    setSoSearch(!soSearch);
    setSelectedProfileType(value);
    setSearchVisible(!isSearchVisible);
    setFinalVal(!finalVal);
  };
  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchVisible(false);
    }
  };
  const [selectedValue, setSelectedValue] = useState("");

  const toggleSearchs = (type, value) => {

    profileGet()
    setSelectedValue(type);
    localStorage.setItem('val', type)
    setSoSearch(true);
    setSearchVisible(!isSearchVisible);
    setFinalVal(!finalVal);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [activeTabs, setActiveTabs] = useState('');

  const handleClick = (tabName) => {
    setActiveTabs(tabName)
    // setSearchVisible(!isSearchVisible);
    setFinalVal(false);
    setSoSearch(false);
    // const isActive = activeTabs.includes(tabName);
    // if (isActive) {
    //     setActiveTabs(activeTabs.filter((tab) => tab !== tabName));
    // } else {
    //     setActiveTabs([...activeTabs, tabName]);
    // }
  };
  const backPage = () => {
    setSearchVisible(!isSearchVisible);
    setFinalVal(!finalVal);
    setSoSearch(!soSearch);
    setSelectedValue("");
  };
  const tabs = ["Incubators", "Business Forms", "Banks", "NGO", "MNCs"];
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState({});
  const [roleDetails, setSoleDetails] = useState([]);
  const profileGet = () => {
    axios
      .get("./json/profiledetails.json", "")
      .then((result) => {
        if (result.data) {

          setData(result.data);
          var sam = localStorage.getItem('val')
          const values = result.data.find(name => name.profileType === sam);
          setSoleDetails(values.role)

        }
      })
      .catch((err) => { });
  };
  const StartNow = () => {
    navigator('/ecosyetem')
  }
  const redirectToUrl = () => {
    navigator('/smartcard')
    // Redirect to the specified URL
    // window.open('https://startuptn.in/startuptn-smart-card/', '_blank', 'noopener,noreferrer');
  };
  const handleBooknow = () => {
    navigator('/fest')
  }
  return (
    <div className="main_home_bg">

      <Navbar />
      <div className="sample">
        <Swiper modules={[Navigation]} spaceBetween={30}
          slidesPerView={1}
          onSlideChange={handleSlideChange}
          autoplay={{ delay: 1000 }}
          pagination={{ clickable: true }}
        >
          {/* <SwiperSlide>
           <Thiruvizha24 />
          </SwiperSlide> */}
          <SwiperSlide>
            <div className="banner-slide1">
              <div className='video-background'>
                <video id="video" className='video_css banner-image' autoPlay loop muted={isMuted} onClick={handlePlay}>
                  {/* <source src='/images/Home Page Video.mp4' type="video/mp4" /> */}
                  <source src='https://startuptn-public-files.s3.ap-south-1.amazonaws.com/homepage-banner-bg.mp4' type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="container banner-content1 text-center">
                <Grid container
                  spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <h1 className="make_h1">  Making <span className={` ${isTamil ? 'tamiltext' : 'englishtext'}`}>{isTamil ? "தமிழ்நாடு" : "Tamil Nadu"}</span></h1> */}
                    <h1 className="make_h1">  Making <span className={` ${isTamil ? 'tamiltext' : 'englishtext'}`}>
                      {/* <Typewriter
                      words={[isTamil ? "தமிழ்நாடு" : "Tamil Nadu"]}
                      loop={true}  
                      cursor
                      cursorStyle='|'
                      typeSpeed={200}  
                      deleteSpeed={50}  
                      delaySpeed={2000}  
                    /> */}
                      <Typewriter
                        words={[isTamil ? "தமிழ்நாடு" : "Tamil Nadu"]}
                        loop={true}
                        cursor
                        cursorStyle='|'
                        typeSpeed={200}
                        deleteSpeed={50}
                        delaySpeed={2000}
                      />
                    </span></h1>
                    <h1 className="global_h1"> A Global St<img src="/images/Profile/Rocket-gif-unscreen.gif" alt="" className="sml-racket" />rtup Destination!</h1>
                    {/* <img src="./images/white-racket.png" alt="" className="sml-racket" /> */}
                  </Grid>
                </Grid>

                {/* <BannerFilter /> */}
                <div className="text-center mouse-icon-1" >
                  <img src="./images/mouse-icon.png" alt="" className="c-pointer" onClick={handleDownBtn} />
                </div>
              </div>
            </div>

          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="banner-slide">
              <img src="./images/banner-ligin-bg.png" alt="Slide 1" className="banner-image" />
              <div className="container banner-content1">
                <Grid container
                  spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                    <div className="set-center">
                      <div className="text-white">
                        <h4 className="make_h4 mb-4">Smart Moves, Smart Savings: </h4>
                        <h1 className="get_h1 mb-4">Get StartupTN's SmartCard!</h1>
                        <p className="font-sml mb-4">"Boost your startup's growth with discounted services through StartupTN's SmartCard!</p>
                        <button className="banner-apply-btn">Apply Now</button>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <div className="img-mobile2">
                      <img src="./images/home-card-img.png" alt="" className="img-fluid" />
                    </div>
                  </Grid>
                </Grid>
                <div className="text-center mouse-icon-2" >
                  <img src="./images/mouse-icon.png" alt="" className="c-pointer" onClick={handleDownBtn} />
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="banner-slide">
              <img src="./images/banner-ligin-bg.png" alt="Slide 1" className="banner-image" />
              <div className="container banner-content1">
                <Grid container
                  spacing={2}>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <div className="set-center2">
                      <div className="text-white">
                        <h4 className="make_h4 mb-4">Ultimate Branding Course:</h4>
                        <h1 className="get_h1 mb-4">Nil, Brand, Sell!</h1>
                        <p className="font-sml mb-4">by Pravin Shekar - an Outlier Marketer</p>
                        <button className="banner-apply-btn">Apply Now</button>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div className="img-mobile">
                      <img src="./images/brand-banner.png" alt="" className="img-fluid" />
                    </div>
                  </Grid>
                </Grid>
                <div className="text-center mouse-icon-2" >
                  <img src="./images/mouse-icon.png" alt="" className="c-pointer" onClick={handleDownBtn} />
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
        <div className="center-postion">
          <div className="container">
            {/* {activeIndex === 1 && (
              <BannerFilter />
            )} */}
            <BannerFilter />
          </div>
        </div>
      </div>
      <div className="Cm-photo-div" id="about">
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-content-homepage">
                <h4 className="mb-4"><b>“We have set ourselves an ambitious  target of becoming a  $1 Trillion economy  by 2030”</b></h4>
                {/* <p>
                “We have set ourselves an ambitious target of becoming a $1 Trillion economy by 2030”
                </p> */}
                <h6>- Thiru. M.K. Stalin</h6>
                <p className="sml-p">Hon'ble Chief Minister of Tamil Nadu</p>
                <div className="left-content-actions">
                  {/* <button className="new-viewbtn">
                      View More
                    </button> */}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="cm-image">
                <img
                  className="cmimag img-fluid"
                  src={"./images/cmimage.png"}
                  alt="cmimage"
                />
              </div>
            </Grid>
          </Grid>

        </Container>
      </div>
      <section className="awards-recognitions-section">
        <Container>
          <h4 ><span className="award_span">Awards and Recognitions</span></h4>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={5}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="awards-recognitions-div">
                <div className="awards-images">
                  <img src="/images/logo/startupindia_logo.png" alt="" />
                </div>

                <h3>Best Performer <br /> State 2022</h3>
                <p>Tamil Nadu was recognized as the 'Best Performer' in the Startup India Rankings for the year 2022. </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="awards-recognitions-div">
                <div className="awards-images">
                  <img src="/images/logo/startup-genome.png" alt="" />
                </div>
                <h3>Chennai - Asia’s 18th <br />
                  Rank</h3>
                <p>Chennai was ranked 18th in Asia in the Global Startup Ecosystem Report 2023 by Startup Genome </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="awards-recognitions-div">
                <div className="awards-images">
                  <img src="/images/logo/Outlook.png" alt="" />
                </div>
                <h3>Top 3 in Startup Outperformers 2023</h3>
                <p>Tamil Nadu ranked among the top 3 states in the Startup Outperformers 2023 rankings by Outlook Business Magazine.</p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <div className="Startups-count">
        <Container>
          <div className="numbered-div">
            <div className="det-lines">
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/green-energy (1) 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.recognisedStartups}</h3>
                  <p>Recognised Startups</p>
                </div>
              </div>
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/Group 1707479617.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.incubationCentres}</h3>
                  <p>Incubation Centres</p>
                </div>
              </div>
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/startup 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.portfolioStartups}</h3>
                  <p> Portfolio Startups</p>
                </div>
              </div>
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/diagram 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.sectors}</h3>
                  <p>Sectors</p>
                </div>
              </div>

              {/* <div className="startup-info">
                <div>
                  <img src={" ./images/money (1) 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.totalAmount}</h3>
                  <p>Fund Disbursed</p>
                </div>
              </div> */}
            </div>
          </div>
        </Container>
      </div>
      {/* <div className="Startups-count">
        <Container>
          <div className="numbered-div">
            <div className="det-lines">
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/green-energy (1) 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.recognisedStartups}</h3>
                  <p>Recognised Startups</p>
                </div>
              </div>
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/Group 1707479617.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.incubationCentres}</h3>
                  <p>Incubation Centres</p>
                </div>
              </div>
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/startup 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.portfolioStartups}</h3>
                  <p> Portfolio Startups</p>
                </div>
              </div>
              <div className="startup-info">
                <div className="matrix">
                  <img src={"./images/diagram 1.svg"} alt="" />
                </div>
                <div className="det-font">
                  <h3>{dashboardData.sectors}</h3>
                  <p>Sectors</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div> */}
      <MapSection />
      <HomeServicesSection />
      <HomeEventSection />
      <section className="home-card-div">
        <div className="container-fluid">
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="smart">
                <img src="./images/home-card-img.png" alt="" className="homecard-img img-fluid" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <div>
                <div className="avail-now-btn  w-100">
                  <div className="py-3 smart-card-content">
                    <h5 className="font-color-orign">Become an Ultimate Member of TN Startup Ecosystem!</h5>
                    <p className="card-div-p">Unlock unbelievable offers and benefits exclusively only for SmartCard users.</p>
                  </div>
                  <div>
                    <button className="avail-btn" onClick={redirectToUrl}>Avail Now</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="department-section">
        <Container>
          <div className="event-header mb-5">
            <h4><b>GoTN Organizations Facilitating Startups</b></h4>
          </div>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://editn.in/" target="_blank">
                    <img src="./images/dep-01.png" alt="" className="dep-img" style={{ mixBlendMode: 'darken' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://fametn.com/ " target="_blank">
                    <img src="./images/dep-14.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://www.fort.forgeforward.in/ " target="_blank">
                    <img src="./images/dep-13.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className=" border-bottom">
                <div className="departments-img">
                  <Link to="https://www.forge-iv.co/" target="_blank">
                    <img src="./images/dep-11.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://www.itnthub.tn.gov.in/ " target="_blank">
                    <img src="./images/dep-02.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://www.naanmudhalvan.tn.gov.in/" target="_blank">
                    <img src="./images/dep-15.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://tansidco.org/" target="_blank">
                    <img src="./images/dep-03.png" alt="" className="dep-img" style={{ mixBlendMode: 'darken' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className=" border-bottom">
                <div className="departments-img">
                  <Link to="https://sipcotweb.tn.gov.in/" target="_blank">
                    <img src="./images/dep-19.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://startuptn.in/" target="_blank">
                    <img src="./images/blue horizontal.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://www.vkp-tnrtp.org/ " target="_blank">
                    <img src="./images/dep-18.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img" >
                  <Link to="https://www.tamilvu.org/" target="_blank">
                    <img src="./images/dep-16.png" alt="" className="dep-img" style={{ width: '100px', height: '100px' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://www.tn-pmfme.com/login" target="_blank">
                    <img src="./images/dep-07.png" alt="" className="dep-img" style={{ mixBlendMode: 'darken' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://ticelbiopark.com/" target="_blank">
                    <img src="./images/dep-05.png" alt="" className="dep-img" style={{ mixBlendMode: 'darken' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://tidco.com/" target="_blank">
                    <img src="./images/dep-17.png" alt="" className="dep-img" style={{ width: '130px', height: '100px', mixBlendMode: 'darken' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://tngreencompany.com/home " target="_blank">
                    <img src="./images/dep-12.png" alt="" className="dep-img" style={{ mixBlendMode: 'darken' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right border-bottom">
                <div className="departments-img">
                  <Link to="https://tnifmc.com/" target="_blank">
                    <img src="./images/dep-04.png" alt="" className="dep-img" />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right ">
                <div className="departments-img">
                  <Link to="https://www.tnrise.co.in/" target="_blank">
                    <img src="images/home-logo-1.png" alt="" className="dep-img" style={{ width: '100px', height: '100px', mixBlendMode: 'darken', objectFit: 'contain' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right ">
                <div className="departments-img">
                  <Link to="https://tahdco.com/" target="_blank">
                    <img src="images/home-logo-2.png" alt="" className="img-fluid dep-img" style={{ width: '130px', height: '100px', mixBlendMode: 'darken', objectFit: 'contain' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right ">
                <div className="departments-img">
                  <Link to="https://www.taicobank.in/" target="_blank">
                    <img src="images/home-logo-3.png" alt="" className="dep-img" style={{ mixBlendMode: 'darken' }} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="border-right ">
                <div className="departments-img">
                  <Link to="https://tnclimatechangemission.in/home/" target="_blank">
                    <img src="images/home-logo-4.png" alt="" className="dep-img" style={{ width: '100px', height: '100px', mixBlendMode: 'darken', objectFit: 'contain' }} />
                  </Link>
                </div>
              </Grid>

            </Grid>
          </div>
        </Container>
      </section>
      <section className="news-sms-section">
        <Container>
          <div className="news-sms-details">
            <img src="./images/racket-img.png" alt="" className="mb-3" />
            <h4 className="font-color-blue mb-3"><b>Let’s build innovation driven economy together!!</b></h4>
            <h5 className="mb-2">Curious about how Startup TN can accelerate your success? </h5>
            <p className="mb-3 font-color-gray">Enter your Email Address below and stay tuned.</p>
            {/* <form onSubmit={handleSubmit(handleNewsLetter)}> */}
            <div>
              <div className="home-join">
                <div className="p-relative">
                  <img src="./images/input-email.png" alt="" className="input-email-img" />
                  <input className="input-home" type="email" placeholder="Email Address" value={emailId} onInput={(e) => handleEmailId(e)} />
                </div>
                <button className="join-button" type="submit" onClick={handleNewsLetter}>Join us</button>
              </div>
              {
                checkEmail === true && (
                  <p className="text-danger email-require">Email is required</p>
                )
              }
              {
                emailErrorMsg && (
                  <p className="text-danger email-errors">{emailErrorMsg}</p>
                )
              }
            </div>
            {/* </form> */}
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default HomePage